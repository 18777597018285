import {
  FaTwitter,
  FaCodepen,
  FaDev,
  FaDribbble,
  FaJsfiddle,
  FaStackOverflow,
  FaGithub,
  FaCode,
  FaLinkedinIn,
  FaFigma,
  FaCamera,
  FaKaggle,
} from "react-icons/fa";

export const profilePicture =
  "https://res.cloudinary.com/iarenotamnot/image/upload/v1662748887/profilepics/lilpony-headsmile.jpg";

export const name = "Phil Conners";

export const bio = "Hunter && Gatherer";

export const social = [
  {
    name: "Twitter",
    url: "https://twitter.com/VaLandscapes",
    icon: FaTwitter,
    color: "blue.300",
  },
  {
    name: "Codepen",
    url: "https://codepen.com/uvacoder",
    icon: FaCodepen,
    color: "pink.700",
  },
  {
    name: "Dev.to",
    url: "https://www.dev.to/fu43033",
    icon: FaDev,
    color: "blue",
  },
  {
    name: "Dribbble",
    url: "https://www.dribbble.com/uvacoder",
    icon: FaDribbble,
    color: "red",
  },
  {
    name: "GitHub",
    url: "https://github.com/uvacoder",
    icon: FaGithub,
    color: "purple",
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/in/alecbcampbell/",
    icon: FaLinkedinIn,
    color: "blue.500",
  },
  {
    name: "JsFiddle",
    url: "https://jsfiddle.net/user/fiddles/all/",
    icon: FaJsfiddle,
    color: "red.500",
  },
  {
    name: "Stackoverflow",
    url: "https://stackoverflow.com/users/17330377/uvacoder",
    icon: FaStackOverflow,
    color: "green.500",
  },
  {
    name: "Kaggle",
    url: "https://www.kaggle.com/ricrik",
    icon: FaKaggle,
    color: "yellow.500",
  },
  {
    name: "Figma",
    url: "https://www.figma.com/@Doc22940",
    icon: FaFigma,
    color: "blue.500",
  },
];

export const links = [
  {
    name: "Portfolio",
    desc: "Personal Web Development Website",
    url: "https://abclinks.vercel.app",
    icon: FaCode,
    color: "blue.300",
  },
  {
    name: "TC Photo & Beauty",
    desc: "Wedding Photography, Videography & Hair",
    url: "https://tcphotobeauty.com",
    icon: FaCamera,
    color: "blue.300",
  },
];
